import React, { useRef, useState, useEffect } from 'react'
import SafeLink from '../components/sfc/safelink'
import navStyles from '../styles/project-modules/navchapter.module.less'
import { usePages } from '../components/hooks/usePages'
import Headroom from 'react-headroom'

export default React.memo(function NavChapters({
  thisPageDetails,
  pageContext,
}) {
  const [pages, comingSoon] = usePages()

  let scrollRef = useRef(null)

  //create refs for each link in the nav for scroll purposes
  let [ticking, setTicking] = useState(false)
  let [scrollRightVisible, setscrollRightVisible] = useState(true)
  let [scrollLeftVisible, setscrollLeftVisible] = useState(false)

  function scrollNext(el) {
    //calculate scroll positions and width so that
    //we can jump forward by roughly one  chapter
    let scrollWidth = el.scrollWidth
    // let clientWidth = el.clientWidth
    let chapterWidth = scrollWidth / 7

    el.scrollLeft += chapterWidth
  }

  function scrollPrev(el) {
    //calculate scroll positions and width so that
    //we can jump forward by roughly one  chapter
    let scrollWidth = el.scrollWidth
    // let clientWidth = el.clientWidth
    let chapterWidth = scrollWidth / 7

    el.scrollLeft -= chapterWidth
  }

  //if we have a width ready for our scroller nav, set it now
  useEffect(() => {
    const initialScrollEl = scrollRef.current
    handleScroll(initialScrollEl)

    function handleScroll(el) {
      //calculate scroll positions and width so that
      //the overlay can remove when we're at the end
      let scrollWidth = el.scrollWidth
      let clientWidth = el.clientWidth
      let scrollLeft = el.scrollLeft

      if (scrollWidth - scrollLeft <= clientWidth) {
        setscrollRightVisible(false)
      } else {
        setscrollRightVisible(true)
      }

      if (scrollLeft > 0) {
        setscrollLeftVisible(true)
      } else {
        setscrollLeftVisible(false)
      }
    }

    function onScrollNav(e, ticking) {
      if (!ticking) {
        window.requestAnimationFrame((d) => handleScroll(e.target))
      }

      setTicking(true)
    }

    //add event listener to scrolling
    scrollRef.current.addEventListener('scroll', (e) => onScrollNav(e))
    window.addEventListener('resize', () => handleScroll(initialScrollEl))

    return () => {
      scrollRef.current.removeEventListener('scroll', (e) => onScrollNav(e))
      window.removeEventListener('resize', () => handleScroll(initialScrollEl))
    }
  }, [scrollRef, ticking])

  return (
    <Headroom
      style={{
        zIndex: '2147483647',
      }}
    >
      <nav className={navStyles.nav}>
        <div className={navStyles.top}>
          <a href="/" target="_blank" rel="noopener noreferrer">
            <div>
              <img
                className="topper-nav-desk-logo"
                alt="Logo"
                src="https://files.sfchronicle.com/static-assets/logos/sf-white.png"
              ></img>
              <img
                className="topper-nav-mobile-logo"
                alt="Logo"
                src="https://files.sfchronicle.com/static-assets/logos/sf-square-white.png"
              ></img>
            </div>
          </a>
          <div className={navStyles.podcast}>
            <span className={navStyles.label}>Listen ⇢</span>
            <SafeLink
              href="https://www.sfchronicle.com/projects/podcasts/the-doodler"
              withinProject={false}
              withinSite={false}
              children={'the doodler podcast'}
              className={
                pageContext.slug === 'podcast'
                  ? navStyles.activeLink
                  : navStyles.link
              }
            ></SafeLink>
          </div>
        </div>
        <div className={navStyles.bottom}>
          <div className={navStyles.storiesContainer} ref={scrollRef}>
            <div className={navStyles.stories}>
              <span className={navStyles.label}>Read ⇢</span>
              {pages.map((page, idx) => {
                return (
                  page.context &&
                  page.context.slug !== 'podcast' && (
                    <SafeLink
                      id={`chapter-${idx}`}
                      href={page.path}
                      withinProject={true}
                      key={page.context.slug}
                      children={page.context.short_title}
                      className={
                        page.context.slug === thisPageDetails.slug
                          ? navStyles.activeLink
                          : navStyles.link
                      }
                    ></SafeLink>
                  )
                )
              })}
              {comingSoon.map((page) => (
                <span className={navStyles.comingSoon} key={page.Short_Title}>
                  {page.Short_Title}
                </span>
              ))}
            </div>
            <button
              className={`${navStyles.overlayLeft} ${
                !scrollLeftVisible && `hidden`
              }`}
              onClick={() => {
                scrollPrev(scrollRef.current)
              }}
            >
              <span>⇠</span>
            </button>
            <button
              className={`${navStyles.overlay} ${
                !scrollRightVisible && `hidden`
              }`}
              onClick={() => {
                scrollNext(scrollRef.current)
              }}
            >
              <span>⇢</span>
            </button>
          </div>
        </div>
      </nav>
    </Headroom>
  )
})
