import React from 'react'
import sectionStyles from '../styles/project-modules/partners.module.less'
import udfLogo from '../data/sfc/images/udf_logo_light.png'
import neonHumLogo from '../data/sfc/images/neon_hum_logo.png'

export default React.memo(function Partners(props) {
  return (
    <section className={sectionStyles.container}>
      <h3 className={sectionStyles.hed}>Presented in partnership with</h3>
      <div className={sectionStyles.logos}>
        <img
          className={sectionStyles.udfLogo}
          alt="Ugly Duckling Films Logo"
          src={udfLogo}
        ></img>
        <img
          className={sectionStyles.neonHumLogo}
          alt="Neon Hum Logo"
          src={neonHumLogo}
        ></img>
      </div>
    </section>
  )
})
