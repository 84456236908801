import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import Headroom from 'react-headroom'
import { getNav, appCheck } from 'sfc-utils'
import navStyles from '../../styles/nav.less'
import { setRichieParam } from './component-helpers/utilfunctions'

//TO DO: Remove overlay if we hit the end

const NavTop = ({ meta, url_add = '', thisPageDetails, pageContext }) => {
  let [showSubnav, setShowSubnav] = useState(false)
  let [navClass, setNavClass] = useState('')

  // Override these with link objects (example below) if you want to customize
  let navLink = {
    text: 'Special Podcast Series',
    url: 'https://www.sfchronicle.com/projects/podcasts/the-doodler',
    target: '_blank',
  }
  let sectionArray = null

  // Enable the social popup
  let handleNavClick = (e) => {
    // Handle facebook
    let el = e.target.closest('#topper-nav-facebook-icon')
    if (el && e.currentTarget.contains(el)) {
      const link = el.getAttribute('href')
      e.preventDefault()
      window.open(link, 'facebook-share-dialog', 'width=626,height=436')
    }
    // Handle subnav (but only if there are nav items)
    if (sectionArray && sectionArray.length > 0) {
      el = e.target.closest('#nav-title')
      if (el && e.currentTarget.contains(el)) {
        e.preventDefault()
        setShowSubnav(!showSubnav)
      }
      // Handle closing subnav
      el = e.target.closest('#subnav')
      if (el && e.currentTarget.contains(el)) {
        setShowSubnav(false)
      }
    }
  }

  //If this is CT, we need to reset state
  let startingHTML = ''
  if (meta.PROJECT.MARKET_KEY !== 'CT') {
    startingHTML = getNav(meta, url_add, null, navLink, sectionArray)
  }
  let [navHTML, setNavHTML] = useState(startingHTML)

  // Setting nav now so that other CT markets can generate
  useEffect(() => {
    sectionArray = setRichieParam(sectionArray)
    setNavHTML(getNav(meta, url_add, null, navLink, sectionArray))

    // Set the navClass
    let tempNavClass = ''
    if (showSubnav) {
      tempNavClass += ' show-subnav'
    }
    if (!appCheck()) {
      tempNavClass += ' not-app'
    }
    setNavClass(tempNavClass)
  }, [])

  return (
    <Headroom
      style={{
        zIndex: '2147483647',
      }}
    >
      <div
        className={navClass}
        onClick={(e) => {
          handleNavClick(e)
        }}
        /* 
        getNav params:
          1. meta 
          2. url_add 
          3. force color ("white", "black" or null)
          4. navlink object {
              text,
              url,
              target
            }
          5. array of link objects for subnav 
        */
        dangerouslySetInnerHTML={{ __html: navHTML }}
      />
    </Headroom>
  )
}

NavTop.propTypes = {
  meta: PropTypes.object.isRequired,
  url_add: PropTypes.string,
}

export default NavTop
