import React from 'react'
import LazyLoad from 'react-lazyload'
import PropTypes from 'prop-types'
import wcmimageStyles from '../../styles/modules/wcmimage.module.less'
import { useStaticQuery, graphql } from 'gatsby'
const currentEnv = process.env.GATSBY_DEPLOY_ENV

const WCMImage = ({ wcm, alt, cap, cred, lz, className, related = false }) => {
  // Use GraphQL to grab the data for the WCM photo we want
  const data = useStaticQuery(graphql`
    query PhotoQuery {
      allWcmPhotos {
        nodes {
          photo {
            ratio
            wcmid
          }
        }
      }
    }
  `)

  let photoRatio = '56.25%' // Default to 16/9
  let matchedPhoto = data.allWcmPhotos.nodes.find((item) => {
    return item.photo.wcmid.toString() === wcm.toString()
  })
  if (!matchedPhoto && currentEnv !== 'development') {
    throw `WCMImage error: No matching ID for ${wcm} present in the array at the top of gatsby-node.js! If it's already there, you might need to reboot dev.`
  }
  if (matchedPhoto) {
    // Set ratio of the actual photo like a legit hacker
    photoRatio = related ? '56.25%' : matchedPhoto.photo.ratio * 100 + '%'
  } else {
    // Alert that things will go wrong on deploy
    console.error(
      `No matching ID for ${wcm} found in gatsby-node.js! This is fine for development, but it will error on deploy!`
    )
  }

  // Get serious about alt tags
  if (!alt) {
    throw `WCMImage error: Image for ${wcm} needs an alt tag! Please add a good, descriptive alt tag. Suggestion from Mozilla: When choosing alt strings for your images, imagine what you would say when reading the page to someone over the phone without mentioning that there's an image on the page.`
  }

  //create srcsets, slightly different for related stories

  const defaultSrcSet = `https://s.hdnux.com/photos/0/0/0/${wcm}/0/400x0.jpg 325w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/768x0.jpg 768w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/1366x0.jpg 1366w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/1920x0.jpg 1920w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/2560x0.jpg 2560w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/3840x0.jpg 3840w,`

  const defaultSrc = `https://s.hdnux.com/photos/0/0/0/${wcm}/0/325x0.jpg`

  const relatedSrcSet = `https://s.hdnux.com/photos/0/0/0/${wcm}/0/premium_gallery_landscape.jpg 325w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/premium_gallery_landscape.jpg 768w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/premium_gallery_landscape.jpg 1366w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/premium_gallery_landscape.jpg 1920w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/premium_gallery_landscape.jpg 2560w,
  https://s.hdnux.com/photos/0/0/0/${wcm}/0/premium_gallery_landscape.jpg 3840w,`

  const relatedSrc = `https://s.hdnux.com/photos/0/0/0/${wcm}/0/premium_gallery_landscape.jpg`

  // Conditionally lazyload if we want to and have the capability
  const ConditionalWrapper = ({ condition, wrapper, children }) =>
    condition ? wrapper(children) : children

  function getImageClass(className) {
    switch (className) {
      case 'float-left':
        return `${wcmimageStyles.cFloatLeft}`
      case 'float-right':
        return `${wcmimageStyles.cFloatRight} `
      case 'full-width':
        return `${wcmimageStyles.cFigFull}`
      case 'float-right, constrained':
        return `${wcmimageStyles.cFloatRight} ${wcmimageStyles.constrained}`
      case 'float-left, constrained':
        return `${wcmimageStyles.cFloatLeft} ${wcmimageStyles.constrained}`
      case 'group':
        return `${wcmimageStyles.cGroupItem}`
      default:
        return `${wcmimageStyles.cFigFitOuter}`
    }
  }
  return (
    <figure className={getImageClass(className)}>
      <div
        style={{
          paddingBottom: photoRatio,
          overflow: 'hidden',
          position: 'relative',
        }}
      >
        <ConditionalWrapper
          condition={!lz}
          wrapper={(children) => (
            <LazyLoad offset={300} resize once>
              {children}
            </LazyLoad>
          )}
        >
          <img
            style={{ position: 'absolute' }}
            className={wcmimageStyles.cImg}
            // "lazy" won't work on older browsers, which is why we use LazyLoad conditionally
            loading="lazy"
            src={related ? relatedSrc : defaultSrc}
            // PLEASE INCLUDE ALTS
            alt={alt}
            srcSet={related ? relatedSrcSet : defaultSrcSet}
          />
        </ConditionalWrapper>
      </div>
      {cap && cred && (
        <figcaption className={wcmimageStyles.cFigCap}>
          {cap} <span className={wcmimageStyles.cFigCred}>{cred}</span>
        </figcaption>
      )}
      {!cap && cred && (
        <figcaption className={wcmimageStyles.cFigCap}>
          <span className={wcmimageStyles.cFigCred}>{cred}</span>
        </figcaption>
      )}
      {cap && !cred && (
        <figcaption className={wcmimageStyles.cFigCap}>{cap}</figcaption>
      )}
    </figure>
  )
}

WCMImage.propTypes = {
  wcm: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
  alt: PropTypes.string.isRequired,
  cap: PropTypes.string,
  cred: PropTypes.string,
  className: PropTypes.string,
}

export default WCMImage
