import React from 'react'
import PropTypes from 'prop-types'
import wcmimageStyles from '../styles/modules/wcmimage.module.less'
import Vimeo from '@u-wave/react-vimeo'
import LazyLoad from 'react-lazyload'

function getImageClass(className) {
  switch (className) {
    case 'float-left':
      return `${wcmimageStyles.cFloatLeft}`
    case 'float-right':
      return `${wcmimageStyles.cFloatRight} `
    case 'full-width':
      return `${wcmimageStyles.cFigFull}`
    case 'fit-outer':
      return `${wcmimageStyles.cFigFitOuter}`
    default:
      return `${className && className}`
  }
}
function Video({ className, id, cap, cred, muted, lazy = true }) {
  return (
    <figure className={getImageClass(className)}>
      {lazy ? (
        <LazyLoad offset={400} resize once classNamePrefix={'video-16-9-lz'}>
          <Vimeo
            video={id}
            // autoplay="true"
            muted={muted}
            controls={!muted}
            loop={muted}
            responsive={false}
            background={muted}
            id={`video-${id}`}
            autopause={!muted}
            showByline={false}
            showTitle={false}
            showPortrait={false}
          />
        </LazyLoad>
      ) : (
        <div className="video-16-9-lz-wrapper">
          <Vimeo
            video={id}
            // autoplay="true"
            muted={muted}
            controls={!muted}
            loop={muted}
            responsive={false}
            background={muted}
            id={`video-${id}`}
            autopause={!muted}
            showByline={false}
            showTitle={false}
            showPortrait={false}
          />
        </div>
      )}

      {cap && cred && (
        <figcaption className={wcmimageStyles.cFigCap}>
          {cap} <span className={wcmimageStyles.cFigCred}>{cred}</span>
        </figcaption>
      )}
      {!cap && cred && (
        <figcaption className={wcmimageStyles.cFigCap}>
          <span className={wcmimageStyles.cFigCred}>{cred}</span>
        </figcaption>
      )}
      {cap && !cred && (
        <figcaption className={wcmimageStyles.cFigCap}>{cap}</figcaption>
      )}
    </figure>
  )
}

Video.propTypes = {
  className: PropTypes.string,
  id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  cap: PropTypes.string,
  cred: PropTypes.string,
  muted: PropTypes.bool,
}

export default React.memo(Video)
