/* Layout wraps all pages so updates here effect everything */

import React, { Fragment } from 'react'
import PropTypes from 'prop-types'
import { Helmet } from 'react-helmet'
import NavTop from '../components/sfc/navtop'
import NavChapters from '../components/navchapters'

// Bring in footer
import Footer from './sfc/footer'

// Add SFC utils
import { blendHDN, appCheck, getBrands } from 'sfc-utils'

// Import global styles needed in document
require('../styles/seed.less')

const Layout = ({
  meta,
  url_add = '',
  description = false,
  image = false,
  social_title = false,
  title = false,
  children,
  thisPageDetails,
  pageContext,
}) => {
  let {
    EMBEDDED,
    PROJECT: {
      ISO_MODDATE,
      ISO_PUBDATE,
      OPT_SLASH,
      AUTHORS,
      SUBFOLDER,
      MARKET_KEY,
      DESCRIPTION,
      IMAGE,
      SOCIAL_TITLE,
      TITLE,
      SLUG,
    },
  } = meta

  // let {
  //   SEO_Title: TITLE,
  //   SEO_Description: DESCRIPTION,
  //   Social_Title: SOCIAL_TITLE,
  //   slug: SLUG,
  // } = thisPageDetails

  // let IMAGE =
  //   `https://s.hdnux.com/photos/0/0/0/0/` +
  //   thisPageDetails.Social_ImageID +
  //   `/0/1600x0.jpg`

  // Override these if they exist (but just use the default otherwise)
  meta.MAIN_DOMAIN = 'https://sfchronicle.com'
  const MAIN_DOMAIN = meta.MAIN_DOMAIN
  DESCRIPTION = thisPageDetails.SEO_Description || DESCRIPTION
  IMAGE =
    `https://s.hdnux.com/photos/0/0/0/0/` +
      thisPageDetails.Social_ImageID +
      `/0/1600x0.jpg` || IMAGE
  SOCIAL_TITLE = thisPageDetails.Social_Title || SOCIAL_TITLE
  TITLE = thisPageDetails.SEO_Title || TITLE
  SLUG =
    thisPageDetails.slug === 'podcast'
      ? 'doodler-true-crime-podcast'
      : thisPageDetails.slug
  SUBFOLDER =
    thisPageDetails.slug === 'podcast'
      ? 'projects'
      : 'projects/doodler-true-crime-podcast'

  const analyticsMeta = Object.assign({}, meta)

  analyticsMeta.PROJECT.DESCRIPTION = DESCRIPTION
  analyticsMeta.PROJECT.IMAGE = IMAGE
  analyticsMeta.PROJECT.SOCIAL_TITLE = SOCIAL_TITLE
  analyticsMeta.PROJECT.TITLE = TITLE
  analyticsMeta.PROJECT.SLUG = SLUG
  analyticsMeta.PROJECT.SUBFOLDER = SUBFOLDER
  analyticsMeta.PROJECT.TWITTER_TEXT = DESCRIPTION

  // Set fonts by MARKET_KEY
  switch (MARKET_KEY) {
    case 'SFC':
      require('sfc-utils/fonts/sfc.less')
      break
    case 'Houston':
      require('sfc-utils/fonts/houston.less')
      break
    case 'Albany':
      require('sfc-utils/fonts/albany.less')
      break
    // TK many more
    default:
      require('sfc-utils/fonts/default.less')
      break
  }

  // Determine if app ver
  // Either from the build settings or from the query string
  const isApp = appCheck()

  // Combine our settings with what Hearst puts on page
  let stringHDN = ''
  if (!EMBEDDED) {
    // Put url_add into a new meta object to pass in
    const metaHDN = Object.assign({}, analyticsMeta)

    metaHDN.URL_ADD = url_add
    // Make sure this is free on app
    if (isApp) {
      metaHDN.PAYWALL_SETTING = 'free'
    }
    let blended = blendHDN(metaHDN)
    stringHDN = blended.stringHDN
  }

  // Get brand vars
  const thisBrand = getBrands(MARKET_KEY)

  // Handle author data
  let authorObj = []
  let newAuthor = {}
  try {
    AUTHORS.forEach((author) => {
      newAuthor = {
        '@type': 'Person',
        name: author.AUTHOR_NAME,
        url: author.AUTHOR_PAGE,
      }
      authorObj.push(newAuthor)
    })
  } catch (err) {
    // If it errored, just set to neutral default
    authorObj = {
      '@type': 'Person',
      name: thisBrand.attributes.siteName,
      url: MAIN_DOMAIN,
    }
  }

  return (
    <Fragment>
      {/* Forcing HDN vars in so we can feed them to ensighten */}
      <Helmet
        script={[
          {
            type: 'text/javascript',
            innerHTML: stringHDN,
          },
          {
            type: 'text/javascript',
            innerHTML: `!function(){"use strict";window.addEventListener("message",(function(a){if(void 0!==a.data["datawrapper-height"])for(var e in a.data["datawrapper-height"]){var t=document.getElementById("datawrapper-chart-"+e)||document.querySelector("iframe[src*='"+e+"']");t&&(t.style.height=a.data["datawrapper-height"][e]+"px")}}))}();`,
          },
        ]}
      />
      <Helmet>
        <title>{TITLE}</title>
        <meta name="description" content={DESCRIPTION} />
        <link rel="shortcut icon" href="/favicon.ico" type="image/x-icon" />
        <link
          rel="canonical"
          href={`${MAIN_DOMAIN}/${SUBFOLDER}${OPT_SLASH}${SLUG}/${url_add}`}
        />

        {(isApp || EMBEDDED) && (
          <meta name="robots" content="noindex, nofollow" />
        )}

        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={SOCIAL_TITLE} />
        <meta
          name="twitter:site"
          content={'@' + thisBrand.attributes.twitter}
        />
        <meta
          name="twitter:url"
          content={`${MAIN_DOMAIN}/${SUBFOLDER}${OPT_SLASH}${SLUG}/${url_add}`}
        />
        <meta name="twitter:image" content={IMAGE} />
        <meta name="twitter:description" content={DESCRIPTION} />

        <meta property="og:type" content="article" />
        <meta property="og:title" content={SOCIAL_TITLE} />
        <meta property="og:site_name" content={thisBrand.attributes.siteName} />
        <meta
          property="og:url"
          content={`${MAIN_DOMAIN}/${SUBFOLDER}${OPT_SLASH}${SLUG}/${url_add}`}
        />
        <meta property="og:image" content={IMAGE} />
        <meta property="og:description" content={DESCRIPTION} />

        <script data-schema="NewsArticle" type="application/ld+json">{`{
            "@context": "http://schema.org",
            "@type": "NewsArticle",
            "mainEntityOfPage": {
              "@type": "WebPage",
              "@id": "${MAIN_DOMAIN}/${SUBFOLDER + OPT_SLASH + SLUG + url_add}"
            },
            "headline": "${TITLE}",
            "image": {
              "@type": "ImageObject",
              "url": "${IMAGE}"
            },
            "datePublished": "${ISO_PUBDATE}",
            "dateModified": "${ISO_MODDATE}",
            "author": ${JSON.stringify(authorObj)},
            "publisher": {
              "@type": "Organization",
              "name": "${thisBrand.attributes.siteName}",
              "logo": {
                "@type": "ImageObject",
                "url": "/apple-touch-icon.png",
                "width": "180",
                "height": "180"
              }
            },
            "description": "${DESCRIPTION}"
          }`}</script>

        {/* Only needed if this is an embed on some page */}
        {EMBEDDED && (
          <script
            async
            src="https://projects.sfchronicle.com/shared/js/responsive-child.js"
          ></script>
        )}

        {/* Add jQuery for treg to use ... sigh */}
        {!EMBEDDED && !isApp && (
          <script src="https://projects.sfchronicle.com/shared/js/jquery.min.js"></script>
        )}

        {/* Exclude login logic from embeds and the app */}
        {!EMBEDDED && !isApp && (
          <script src="https://treg.hearstnp.com/treg.js"></script>
        )}

        {/* Include GA and Chartbeat, unless it's an embed */}
        {!EMBEDDED && (
          <script src="https://nexus.ensighten.com/hearst/news/Bootstrap.js"></script>
        )}

        {/* Exclude subscribe logic from embeds and the app */}
        {!EMBEDDED && !isApp && (
          <script defer src="https://cdn.blueconic.net/hearst.js"></script>
        )}
      </Helmet>
      {pageContext.slug === 'podcast' ? (
        <NavTop
          meta={meta}
          thisPageDetails={thisPageDetails}
          pageContext={pageContext}
        />
      ) : (
        <NavChapters
          thisPageDetails={thisPageDetails}
          pageContext={pageContext}
        />
      )}

      {/* Full project included here: */}
      {children}

      {/* Include footer unless it's embedded or the app version: */}
      {!EMBEDDED && !isApp && <Footer meta={meta} />}
    </Fragment>
  )
}

Layout.propTypes = {
  meta: PropTypes.object.isRequired,
  url_add: PropTypes.string,
  children: PropTypes.node.isRequired,
  image: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  description: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
  social_title: PropTypes.oneOfType([PropTypes.string, PropTypes.bool]),
}

export default Layout
