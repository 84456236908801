import { useStaticQuery, graphql } from 'gatsby'
export const usePages = () => {
  const { allSitePage, allPagesSheetJson } = useStaticQuery(
    graphql`
      query pageQuery {
        allSitePage(
          filter: { isCreatedByStatefulCreatePages: { eq: false } }
          sort: { fields: context___nav_order }
        ) {
          nodes {
            path
            context {
              slug
              short_title
              nav_order
            }
          }
        }
        allPagesSheetJson(filter: { live: { eq: false } }) {
          nodes {
            Short_Title
            slug
          }
        }
      }
    `
  )
  return [allSitePage.nodes, allPagesSheetJson.nodes]
}
